.loginWrapper {
    width: 100%;
    text-align: center;
    margin-top: 80px;
    .loginPage {
        display: flex;
        width: 800px;
        text-align: center;
        box-shadow: 0px 0px 5px #d8d8d8;
        height: 400px;
        background: #007a7a;
        border-radius: 25px;
        margin: auto;

        .loginForm {
            width: 400px;
            // flex: 1;
            color: #fff;
            display: inline-block;
            font-weight: 300;
            font-size: 28px;
            .formWrapperlogin{
                margin-top: 50px;
                text-align: center;
            }
        }
        
        .loginCompany {
            width: 400px;
            /* display: flex; */
            // flex: 1;
            border-bottom-left-radius: 20%;
            background: #fff;
            // color: #fff;
            border-top-left-radius: 20%;
            border-bottom-right-radius: 25px;
            border-top-right-radius: 25px;
            display: inline-block;
            .logoCompany{
                margin-top: 120px;
            }
        }
    }
}