.formWrapper {
    .xs .ant-picker-input input{
        font-size: 10px;
    }
    .ant-input{
        &.xs{
            font-size: 10px;
        }
    }
    .xs {
        .ant-select-selection-item{
            font-size: 10px;
        }
    }
    .radioXS{
        font-size: 10px;
    }
    .ant-select-single.ant-select-sm{
        font-size: 10px;
    }

    .cmsFormFontStyle {
        font-size: 10px;
        font-weight: 700;
    }

    .ant-form-item {
        margin-bottom: 5px;
    }

    .ant-form-item-label {
        font-size: 10px;
        font-weight: 600;

        label {
            font-size: 10px;
        }
    }

    .searchdotbtn {
        width: 30px;
        border: 1px solid #a1a1a1;
        height: 30px;
        /* text-align: center; */
        padding-left: 7px;
        background: #168783;
        color: #fff;

        &.sm {
            width: 14px;
            height: 20px;
            padding-left: 2px;
            font-size: 8px;
            margin-top: 6px;
        }
    }

    .mediContainer {
        display: flex;
        font-size: 9.3px;
        font-weight: 700;
        margin-top: 10px;

        span {
            flex: 1;
        }

        .mediRadio {
            flex: 1;
            width: 112px;
            margin-top: 10px;

            &.w50 {
                width: 50px;
                text-align: center;
                margin-top: 0px;
            }

            span {
                color: #a5a5a5;
                font-style: italic;
                font-size: 10px;
                font-weight: 600;
            }
        }
    }

    .dashRight {
        border-right: 1px dashed;
    }

    .m10tb {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .lableText {
        // margin-top: 10px;
        // margin-bottom: 10px;
        font-size: 10px;
        font-weight: 700;

        .lableHightlight {
            width: 28px;
            height: 20px;
            background: #007a7a;
            color: #FFFFFF;
            display: inline-block;
            line-height: 20px;
            margin-right: 10px;
            text-align: center;
            border-radius: 5px;
        }
    }

    .tableClaimHeader {
        background: rgba(0, 0, 0, 0.04);
        padding-left: 5px;
        border-right: 2px solid #fff;

        .txtCenter {
            text-align: center;
        }

        .borderWhite {
            border-right: 2px solid #fff;
        }
    }


    .txtTable {
        .ant-form-item-label {
            label {
                font-size: 8px !important;
                font-weight: 750;
            }
        }
        .ant-form-item-control-input-content{
            font-size: 10px;
        }

    }

    .tableLineNote{
        background: #f4f4f4;
        margin-right: 2px !important;
        margin-left: -2px !important;
        .ant-select-selection-item{
            font-size: 10px !important;
        }
        .ant-input{
            font-size: 10px !important;
        }
        .ant-input-suffix{
            font-size: 10px !important;
        }
        .anticon {
            font-size: 10px !important;
        }
        
    }
    .tableIcdcode{
        .ant-input{
            font-size: 10px !important;
        }
        .ant-input-suffix{
            font-size: 10px !important;
        }
        .anticon {
            font-size: 10px !important;
        }
    }

}


.ub04formWrapper{

    .ubformtext{
        font-size: 10px;
        font-weight: 700;
        padding:5px ;
    }

    .dashRight {
        border-right: 1px dashed;
    }
    .mT22{
        margin-top: 22px;
    }
    .txtCenter{
        text-align: center;
        display: block;
    }

    .searchdotbtn {
        width: 30px;
        border: 1px solid #000;
        height: 30px;
        padding-left: 7px;
        background: #f6f6f6;
        margin-top: 23px;
        &.sm {
            width: 14px;
            height: 20px;
            padding-left: 2px;
            font-size: 8px;
            margin-top: 6px;
        }
    }
    .displayMinInput{
        display: flex;
    }

    .tableHeader{
        border-bottom: 1px dashed;
        font-size: 10px;
        font-weight: 700;
        height: 50px;
        display: table;
        .headerxss{
            width: 30px;
            display: inline-block;
            border-right: 1px dashed;
            padding-left: 5px;
            padding-right: 5px;
            
        }
        .headerxs{
            width: 50px;
            display: inline-block;
            border-right: 1px dashed;
            padding-left: 5px;
            padding-right: 5px;
        }
        .headersm{
            width: 80px;
            display: inline-block;
            border-right: 1px dashed;
            padding-left: 5px;
            padding-right: 5px;
        }
        .headersmm{
            width: 120px;
            display: inline-block;
            border-right: 1px dashed;
            padding-left: 5px;
            padding-right: 5px;
        }
        .headerm{
            width: 180px;
            display: inline-block;
            padding-left: 5px;
            padding-right: 5px;
            height: 30px;
        }
    }

    .tableCell{
        display: inline-block;
        margin: 2px;
        &.xss{
            width: 30px;
        }
        &.xs{
            width: 50px;
        }
        &.sm{
            width: 75px;
        }
        &.smm{
            width: 114px;
        }
        &.m{
            width: 35px;
        }
        &.mm{
            width: 64px;
        }
    }

    .ubTableHeader{
        .ant-col{
            border-right: 1px dashed;
        }
    }

}
