.shadowCard {
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    // height: 370px;
    box-shadow: 0px 0px 5px #d8d8d8;
}

.ant-picker-calendar-mini {
    h4 {
        margin-top: 10px;
    }
}

.ant-table-wrapper .ant-table-thead>tr>th {
    background-color: #007a7a;
    color: #ffffff;
}

.ant-table-tbody>.ant-table-row.hourRow {
    margin-top: 20px;
    border: 2px solid #222;
}

.ant-table-row .ant-table-row-level-0 .hourRow {
    margin-top: 20px;
    border: 2px solid #222;
}

.ant-table-row {
    background-color: #FAFAFA;
}

.ant-table-tbody {
    font-size: 11px;
    font-weight: 600;
}

.ant-table-thead {
    font-size: 11px;
    font-weight: 700;
}

.hourlyRowhightly {
    background: #007a7a;
    padding: 5px;
    border-radius: 5px;
    font-weight: 700;
    color: #fff;
    text-align: right;
    display: block;
}

.headerTime{
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    font-weight: 700;
    background: #007a7a;
    color:#fff;
    margin-bottom: 5px;
}


.mintsRowhightly {
    text-align: right;
    display: block;
}
.mt22{
    margin-top: 22px;
}
.mt24{
    margin-top: 24px;
}
.mt16{
    margin-top: 16px;
}
.iconBtn{
    padding: 5px;
    background-color: #005154;
    border: #222 1px solid;
    border-radius: 5px;
    font-size: 10px;
    color: #ffffff;
}



.appointmentForm {
    .ant-form-item {
        margin-bottom: 5px;
    }

    .ant-picker {
        width: 100%;
    }

    .ant-btn {
        margin: 5px;
        color: #222;
        width: 120px;
        border-radius: 5px;
    }

    .agree {
        background-color: #eee;
        border-radius: 10px;
        font-size: 10px;
        padding: 5px 10px;
    }
}

.appointmentSearchform {
    .ant-typography {
        margin-top: 5px;
    }

    .ant-form-item {
        margin-bottom: 5px;
    }

    .ant-btn {
        margin: 5px;
        color: #222;
        width: 120px;
        border-radius: 5px;
    }

    .searchBtn {
        text-align: right;
        margin-top: 15px;
    }
}
.timeWrapper{
    padding: 0px 8px 0px 0px;
    background-color: #FAFAFA;
    border-bottom: 1px solid #fff;
}

.tagCreate{
    // color: green;
    cursor: pointer;
}

.tip{
    color: #343434;
    .tipLabel{
        width: 70px;
        text-align: right;
        display: inline-block;
        .anticon {
            padding-right: 10px;
            color: #657FFF;
        }
    }
    .status{
        color: #657FFF;
    }
    .icon{
        text-align: center;
        // color: #657FFF;
        width: 100px;
        padding-right: 32px;
        font-weight: 700;
        text-transform: capitalize;
    }
    
    .tipValue{
        width: 100px;
        padding-left: 10px;
        text-align: left;
    }
    
}

// .weeklyTable {
//     display: inline-block;


//     .weeklyTableRow {
//         display: block;
//         width: 150px;
//         text-align: center;
//         padding: 5px;
//         font-size: 11px;
//         border-bottom: 1px solid #D7D7D7;
//         background: #FAFAFA;
//         height: 42px;
//         line-height: 42px;

//         &.time {
//             width: 60px;
//             text-align: right;
//             padding-right: 15px;
//         }

//         &.hourStart {
//             margin-top: 20px;
//         }
//     }

//     .weeklyTableCell {
//         display: inline-block;

//         &.time {
//             width: 60px;
//             text-align: right;
//             padding-right: 15px;
//         }
//     }
// }

.weeklyTableRow {
    height: 34px;
    line-height: 34px;
    font-size: 11px;
    font-weight: 700;
    background-color: #FAFAFA;
    border-bottom: 1px solid #eee;
    &.hourStart{
        background: #007a7a;
        border-radius: 5px;
        color: #fff;
    }
    &.time {
        text-align: right;
        padding-right: 5px;
    }

    &.date {
        text-align: left;
        padding-left: 10px;

        .weeklyTableCell {
            margin-left: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.formSubTitle{
    font-size: 14px;
    color: #007a7a !important;
}

.weeklyHeader{
    height: 50px;
    line-height: 50px;
    background-color: #007a7a;
    color: white;
    font-size: 12px;
    font-weight: 600;
    div{
        text-align: center;
        font-size: 11px;
        font-weight: 700;
    }
}

.headerTable {
    display: block;

    .headerTableCell {
        width: 150px;
        padding: 5px;
        height: 42px;
        line-height: 42px;
        font-size: 12px;
        font-weight: 700;
        display: inline-block;
        text-align: center;

        &.time {
            width: 60px;
        }

        &.hourStart {
            margin-top: 20px;
        }
    }
}

.ant-picker-calendar-header {
    border-radius: 10px;
    background: #007a7a;
    text-align: center;
    justify-content: center;
}

.optionWrapper {
    .ant-form-item {
        margin-bottom: 5px;
    }

    .ant-modal-content {
        padding: 0px;
        border-radius: 20px;
        width: 400px;
        /* text-align: center; */
        margin: auto;
    }

    .ant-modal-header {
        background: linear-gradient(89.98deg, #9647FF 0.01%, #657FFF 99.98%);
        color: #fff;
        padding: 10px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .ant-modal-title {
        color: #fff;
        margin-left: 10px;
        padding: 10px;
    }

    .btn-primary {
        width: 120px;
        /* text-align: center; */
        border-radius: 10px;
        background: linear-gradient(96.43deg, #9647FF 3.58%, #657FFF 85.45%);

        .btn-search {
            width: 80px;
        }
    }

    .btn-default {
        width: 120px;
        border-radius: 10px;
        border: 1px solid #657FFF;
    }
}


.specialEventForm {
    .ant-form-item {
        margin-bottom: 5px;
    }

    .btn-primary {
        width: 220px;
        height: 46px;
        /* text-align: center; */
        border-radius: 25px;
        background: linear-gradient(96.43deg, #9647FF 3.58%, #657FFF 85.45%);
        margin-top: 30px;
        margin-left: 30px;
    }

    .btn-search {
        background: linear-gradient(96.43deg, #9647FF 3.58%, #657FFF 85.45%);
        border-radius: 10px;
        color: #fff;
        width: 50px;
        margin-top: -30px;
    }
}

.btn-primary {
    width: 220px;
    height: 46px;
    /* text-align: center; */
    border-radius: 25px;
    background: linear-gradient(96.43deg, #9647FF 3.58%, #657FFF 85.45%);
    margin-top: 30px;
    margin-left: 30px;
}

// .ant-table table { border-spacing: 0 20px; }
.ant-table-tbody>tr.hourRow {
    border-spacing: 0 20px;
}

.addAppointment {
    .ant-modal-content {
        padding: 0px;

        .ant-modal-header {
            background: linear-gradient(96.43deg, #9647FF 3.58%, #657FFF 85.45%);
            ;
            height: 65px;
            padding: 20px 24px;
            line-height: 65px;
            color: #fff;
        }

        .ant-modal-body {
            padding: 24px;
        }
    }

}