.mini-card {
    height: 150px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 1px 1px 5px #ddd;
}

.dashboard-wrapper {
    .ant-card .ant-card-body {
        padding: 16px;

        .pt-icon {
            font-size: 28px;
            color: #fff;
            background: #4fa9fd;
            width: 45px;
            border-radius: 9px;
            /* margin: auto; */
            text-align: center;

            &.pt-new {
                background-color: #5e5bfd;
            }

            &.pt-old {
                background-color: #7cc177;
            }

            &.apptotl {
                background-color: #ff8a48;
            }
        }


        .pt-count {
            font-size: 26px;
            margin-top: 8px;
            font-weight: 700;
        }

        .pt-text {
            font-size: 13px;
            font-weight: 500;
            color: #c5c5c5;
        }

        .ds-statistic {
            color: rgb(63, 134, 0);
            text-align: right;
            /* background: #f4f4f4; */
            /* width: 77px; */
            padding: 3px;
            border-radius: 10px;

            .ant-statistic-content {
                font-size: 14px;
                margin-top: -3px;
            }
        }
    }
}

.ds-table {
    .ant-table-thead {
        background: none;

        .ant-table-cell {
            background-color: #007a7a;
        }
    }

    .ant-table-row {
        background-color: #ffffff;
    }

    .ant-table-wrapper .ant-table-thead>tr>th {
        background-color: #f7f7f7 !important;
    }
}

.ds-card {
    box-shadow: 0px 0px 5px #eee;
    border-radius: 10px;

    .title-pt-pie {
        font-size: 22px;
        margin-bottom: 5px;
        font-weight: 500;

        p {
            margin: 0px;
        }
    }
}
.title-pt-pie {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 500;

    p {
        margin: 0px;
    }
}

.ds-title {
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: 500;

    p {
        margin-bottom: 0px;
    }
}

.title-app {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin: 16px 0px;
}

.title-mon{
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    margin: 10px;
    display: inline-block;
    padding: 10px 18px;
    background: #fff;
}

.patientInfo {
    font-size: 12px;

    .patientname {
        font-weight: 500;
    }

    .ptInfo {
        display: block;
    }
}

.appointment-cal {
    font-size: 18px;
    margin-top: 28px;
    display: block;

    .appointment-date {
        display: flex;
        flex: 1;
        cursor: pointer;
        .app-dates {
            display: flex;

            .app-date {
                flex: 1 1;
                margin: 8px 9px;
                margin-top: -7px;
                background: #fff;
                padding: 8px 12px;
                font-size: 10px;
                text-align: center;
                color: #cecece;
                border-radius: 8px;
                box-shadow: 1px 1px 5px #bdbdbd;

                &.active {
                    background-color: #7cc177;
                    color: #fff;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

}