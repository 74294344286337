.mt30{
    margin-top: 30px;
}
.mt22{
    margin-top: 22px;
}
.iconSearch {
    width: 26px;
    padding: 0px 5px;
    margin-top: 3px;
    background: #eee;
    border: 1px solid #b5b5b5;
    display: block;
}

.ant-table-cell{
    p{
        margin: 0px;
    }
}

.ant-table-row{
    line-height: 12px;
}