body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu-light,
.ant-menu-light {
  background-color: #163b45;
  color: #8dc487;
}
.ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover{
  color: #fff;
}

.ant-menu-item-selected, 
.ant-menu-item-active{
  color: #fff;
}

.ant-menu-item:hover{
  color: #fff;
}

.ant-menu-light .ant-menu-item-selected{
  color: #fff;
  border-left: 5px solid #8dc487;
  background-color: #163b45;
}

.ant-layout .ant-layout-sider {
  background-color: #163b45;
  color: #8dc487;
}
.ant-menu-inline .ant-menu-item{
  height: 48px;
  line-height: 48px;
}

.ant-menu-item{
  padding-left: 12 !important;
}



.logo-vertical{
  height: 64px;
  background: #fff;
}

.medqick-logo{
  padding: 10px;
}

.logoIcon{
  height: 60px;
}



.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #007a7a;
  background-color: #ffffff;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.700); 
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.95, 0.95, 0.95, 0.95);
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #007a7a;
}

.ant-select-dropdown {
  min-width: 300px !important;
}

.btnPrimaryC {
  background: linear-gradient(90deg, #9647FF 0.01%, #657FFF 99.98%);
  border-radius: 15px;
  width: 120px;
  font-size: 11px;
  margin: 0 10px;
}

.searchBtn {
  padding: 5px;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 25px;
  width: 25px;
  height: 25px;
  background: #007a7a;
  border: 1px solid #eee;
}

.searchdotbtn {
  width: 30px;
  border: 1px solid #a1a1a1;
  height: 30px;
  /* text-align: center; */
  padding-left: 7px;
  background: #f6f6f6;
}

.ant-btn-primary{
  background-color: #007a7a;
  color: #ffffff !important;
}

.ant-tag{
  cursor: pointer;
}

.tdClick{ 
  cursor: pointer;
  color: #007a7a }