
.ant-form-vertical .ant-form-item-label {
    padding: 0px !important;
}
.paitentForm {
    .ant-form-item .ant-form-item-label>label {
        font-size: 12px;
        font-weight: 500;
    }

    .ant-form-vertical .ant-form-item-label {
        padding: 0px !important;
    }

    .mt30 {
        margin-top: 30px;
    }
    .mt22 {
        margin-top: 22px;
    }

    .ant-form-item {
        margin-bottom: 1px;
    }

    .formLable {
        font-size: 12px;
        font-weight: 500;
    }

    .lineStyle {
        margin: 8px 0px;
        font-size: 13px;
        border-color: blue;
    }

    .titleStyle {
        font-size: 16px;
        margin: 10px 0px;
        font-weight: 600;
        display: block;
        &.blue{
            color: blue;
        }
    }

    .iconSearch {
        width: 26px;
        padding: 0px 5px;
        margin-top: 3px;
        background: #eee;
        border: 1px solid #b5b5b5;
        display: block;
    }
    .subStyle{
        font-size: 10px;
        display: block;
    }
}